<template>
  <div id="partners" class="bg-white">
    <div class="container pt-4 pb-8 sm:py-10">
      <div class="h2 pb-3 md:pb-6 md:text-center">
        Deelnemers
      </div>
      <div class="gradient-border">
        <div class="py-2px h-full">
          <div class="bg-white h-full w-full flex justify-center py-10 relative text-md overflow-x-hidden">
            <transition-group tag="div" :name="transitionName" class="py-6 lg:py-12">
              <div v-if="show" :key="current" class="slide">
                <div class="w-5/6 flex justify-center md:justify-between">
                  <a
                    v-for="partner in chunkedPartners[current]"
                    :key="partner.title"
                    :href="partner.website"
                    target="_blank"
                    rel="nofollow noopener"
                    class="inline-block"
                  >
                    <img :src="partner.image" :alt="partner.title" class="h-20 object-contain">
                  </a>
                </div>
              </div>
            </transition-group>
            <div class="button button-prev text-secondary text-md" aria-label="Vorige slide" @click="slide(-1)">
              <font-awesome-icon :icon="['far', 'arrow-left']" aria-hidden="true"/>
            </div>
            <div class="button button-next text-secondary text-md" aria-label="Volgende slide" @click="slide(1)">
              <font-awesome-icon :icon="['far', 'arrow-right']" aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      current: 0,
      transitionName: 'fade',
      show: false,
    };
  },
  computed: {
    ...mapState({
      partners: state => state.partners,
    }),
    chunkedPartners() {
      if (this.partners) {
        const size = this.$device.isMobile ? 1 : 4;
        const partners = [];

        for (let i = 0; i < this.partners.length; i += size) {
          partners.push(this.partners.slice(i, i + size));
        }
        return partners;
      }
      return [];
    },
  },
  mounted() {
    this.show = true;
    this.startSlider();
  },
  methods: {
    slide(dir) {
      dir === 1
        ? (this.transitionName = 'slide-next')
        : (this.transitionName = 'slide-prev');
      const len = this.chunkedPartners.length;
      this.current = (this.current + dir % len + len) % len;
    },
    startSlider() {
      setInterval(() => {
        this.slide(1);
      }, 4000);
    },

  },
};
</script>

<style lang="sass" scoped>

/* FADE IN */
.fade-enter-active
  transition: opacity 1s

.fade-enter
  opacity: 0

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active
  transition: transform 0.5s ease-in-out

.slide-next-enter
  transform: translate(100%)

.slide-next-leave-to
  transform: translate(-100%)

/* GO TO PREVIOUS SLIDE */

.slide-prev-enter-active,
.slide-prev-leave-active
  transition: transform 0.5s ease-in-out

.slide-prev-enter
  transform: translate(-100%)

.slide-prev-leave-to
  transform: translate(100%)

/* SLIDER STYLES */
body
  overflow: hidden
  margin: 0

#slider
  width: 100%
  height: 100px
  position: relative

.slide
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center

.button
  z-index: 10
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px
  position: absolute
  top: calc(50% - 15px)
  left: 1%
  transition: transform 0.3s ease-in-out
  user-select: none

.button-next
  left: auto
  right: 1%

.partner-logo
  object-fit: contain
  height: 90px
  width: auto
</style>
